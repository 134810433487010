import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";

function ProjectCards(props) {
  return (
    <Card className="project-card-view" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", paddingBottom:'30px',paddingTop:'30px' }}>
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body style={{ paddingBottom: "0px" }}>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify", marginTop:'30px' }}>
          {props.description}
        </Card.Text>
      </Card.Body>
      <div style={{ display: "flex", justifyContent: "space-evenly", padding: "10px" }}>
        <Button variant="primary" href={props.ghLink} target="_blank">
          <BsGithub />  
          {props.isBlog ? "Blog" : "GitHub"}
        </Button>
        {!props.isBlog && props.demoLink && (
          <Button
            variant="primary"
            href={props.demoLink}
            target="_blank"
            style={{ marginLeft: "10px" }}
          >
            <CgWebsite />  
            {"Live Preview"}
          </Button>
        )}
      </div>
    </Card>
  );
}
export default ProjectCards;
