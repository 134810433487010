import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Don Brightson </span>
            from <span className="purple"> Chennai, India.</span>
            <br />
            <br />
            <p>Ex-Intern at <span className="purple">Openweaver</span> and <span className="purple">Nullclass</span></p>
            <p>Ex-Software Development Trainee <span className="purple">J.P Morgan Chase</span> and <span className="purple">Hewlett Packard</span></p>
            <p>Recently graduated from <span className="purple">SMIT</span> with a Bachelor's degree in Computer Science and Engineering. </p>
            <p>I'm eager to give back to the community! If you have any projects or services that need attention, feel free to share your ideas with me. I'll evaluate them and get back to you! </p>
            <br />
            <br />
            Apart from coding, I also enjoy
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Chess
            </li>
            <li className="about-activity">
              <ImPointRight /> Reading Novels
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" ,marginTop:'50px'}}>
          “That's the difference between you and me. <br/>You wanna lose small, I wanna win big !” {" "}
          </p>
          <footer className="blockquote-footer">Don Brightson</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
