import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import AttendanceWebsite from "../../Assets/Projects/Attendance_Automation.png";
import CarWebsite from "../../Assets/Projects/Car_Website.png";
import Hotel from "../../Assets/Projects/Hotel_Booking_Website.png";
import Chatify from "../../Assets/Projects/Chatify.png";
import Healthcare from "../../Assets/Projects/Healthcare.png";
import StackoverflowClone from "../../Assets/Projects/Stackoverflow_clone.png";
import TicTacToe from "../../Assets/Projects/Tic-Tac-Toe.png";
import VoiceOfJustice from "../../Assets/Projects/Voice of Justice.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container style={{ marginTop: "75px" }}>
        <h1 className="project-heading">
          My Key/Notable <strong className="purple">Projects</strong>
        </h1>
        <p style={{ color: "white", marginTop: "18px" }}>
          Here are a few projects I've worked on recently:
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Healthcare}
              isBlog={false}
              title="Modern Healthcare Management System"
              description={
                <ul>
                  <li>
                    Utilising the powers of NextJS framework, I developed this application to manage healthcare facilities in a faster and more secure way.
                  </li>
                  <li>
                    Manage patient data, appointments, and prescriptions with ease with this application.
                  </li>
                </ul>
              }
              ghLink="https://github.com/Dbrightson/Modern-Heathcare-Management-System"
              demoLink="https://modern-heathcare-management-system.vercel.app/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={VoiceOfJustice}
              isBlog={false}
              title="AI Assisted Legal Consultation Platform"
              description={
                <ul>
                  <li>
                    Voice of Justice is an innovative project aimed at providing
                    legal aid and advocacy for marginalized communities.
                  </li>
                  <li>
                    The project combines technology and legal expertise to
                    ensure access to justice and raise awareness about social
                    justice issues.
                  </li>
                  <li>
                    It has been featured in the International Journal for
                    Science and Advance Research in Technology (IJSART),
                    highlighting its significant impact and innovative approach.
                  </li>
                  <li>
                    I led the project team, and our work received commendation
                    from various legal experts and human rights organizations.
                  </li>
                </ul>
              }
              ghLink="https://github.com/Data-Sculptor-X/VOJ-ReactJS"
              demoLink="https://voiceofjustice.me/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Chatify}
              isBlog={false}
              title="Chatify - A Real-time Chat Application"
              description={
                <ul>
                  <li>
                    Chatify is a dynamic real-time communication platform
                    designed for seamless text, audio, and video messaging.
                  </li>
                  <li>
                    It caters to both personal and professional communication
                    needs, including social messaging and business
                    collaboration.
                  </li>
                  <li>
                    I am proud that our project Chatify was shortlisted for an
                    expo during the Naan Mudhalvan Success Function held at
                    Nehru Indoor Stadium. Our project was appreciated by the
                    Honorable Chief Minister of Tamilnadu Dr. M.K. Stalin.
                  </li>
                </ul>
              }
              ghLink="https://github.com/chatify-SMIT/Chatify-sunstone"
              demoLink="https://chatify-smit.netlify.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={TicTacToe}
              isBlog={false}
              title="Tic-Tac-Toe"
              description={
                <p>
                  <ul>
                    <li>
                      I developed this out of boredom, give it a shot tho ;)
                    </li>
                    <li>
                      Make a move and wait for the computer to make a move!
                    </li>
                  </ul>
                </p>
              }
              ghLink="https://github.com/Dbrightson/Tic-Tac-Toe"
              demoLink="https://tic-tac-toe-owek.onrender.com"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={CarWebsite}
              isBlog={false}
              title="Car Rental and Booking Website"
              description={
                <ul>
                  <li>
                    This is a basic static website developed using ReactJS to
                    portray my frontend skills.
                  </li>
                  <li>
                    Please note that this application does not support booking facilities as this was a way to portray my frontend development skills.
                  </li>
                  <li>
                    Will add other backend facilties soon!
                  </li>
                </ul>
              }
              ghLink="https://github.com/Dbrightson/Car-Searching-Website"
              demoLink="https://car-searching-website.onrender.com"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Hotel}
              isBlog={false}
              title="Hotel Booking Website"
              description={
                <ul>
                  <li>
                    This Website supports payment facilities using Razorpay, booking confirmation via mail and SMS services as well.
                  </li>
                  <li>
                    I really enjoyed working on this as I developed this for a client in Paris, and it was fun as well as a wonderuful learning journey as well.
                  </li>
                  <li>
                    Not my first freelance project, but surely a dear one
                  </li>
                </ul>
              }
              ghLink="https://github.com/Dbrightson/Hotel-Lisitng-Website"
              demoLink="https://hotel-lisitng-website-client.onrender.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={StackoverflowClone}
              isBlog={false}
              title="Stackoverflow Clone"
              description={
                <ul>
                  <li>
                    This is my very first CRUD application. It has the following
                    features:
                  </li>
                  <ol>
                    <li>
                      Chatbot Integration: Implemented an intelligent Chatbot
                      capable of addressing programming-related inquiries,
                      enhancing user experience and engagement.
                    </li>
                    <li>
                      OTP Authentication: Strengthened security by seamlessly
                      integrating OTP authentication, ensuring user data
                      protection.
                    </li>
                    <li>
                      Subscription Plans: Introduced three distinct subscription
                      plans:
                      <ul>
                        <li>
                          Free Plan: Restricted users to 1 question per day.
                        </li>
                        <li>
                          Silver Plan: Enhanced privileges, allowing 5 questions
                          per day.
                        </li>
                        <li>
                          Gold Plan: Empowered users with unlimited question
                          posting.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Social Media Feature: Overcame a challenging milestone by
                      incorporating a dynamic social media feature. Users can
                      now follow peers, share images, and post videos within
                      their feeds.
                    </li>
                  </ol>
                </ul>
              }
              ghLink="https://github.com/Dbrightson/StackOverflowClient"
              demoLink="https://stackoverflow-client-o3ab.onrender.com"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={AttendanceWebsite}
              isBlog={false}
              title="Attendance Automation System using OpenCV"
              description={
                <p>
                  Designed and Developed an Attendance Automation System for our
                  college. This application can also be generic and used for
                  other universities or institutions too. This website has
                  analytics, Automated attendance using Face-Detection, etc. Do
                  take a look at this masterpiece!
                </p>
              }
              ghLink="https://github.com/santhoshparthiban2002/Attendance-Automation"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
